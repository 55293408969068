import {
  LoadingOutlined,
  PlusOutlined,
  MinusOutlined,
  EditOutlined,
  BarsOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { Button, message, Upload, Select, DatePicker, Alert, Row, Col } from 'antd'
import axios from 'axios'
import { Formik, FieldArray } from 'formik'
import { Form, Input, InputNumber, Checkbox, Radio } from 'formik-antd'
import moment from 'moment'
import { useContext, useState, useEffect, useRef } from 'react'
import Resizer from 'react-image-file-resizer'
import { useQueryClient, useQuery } from 'react-query'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import { EditProfileHandle } from './EditProfileHandle'
import { RecommendedProducts } from './RecommendedProducts'
import baseball from '../../../../assets/images/backgrounds/baseball.jpg'
import basketball from '../../../../assets/images/backgrounds/basketball.jpg'
import bright from '../../../../assets/images/backgrounds/bright-theme.png'
import cottonCandy from '../../../../assets/images/backgrounds/cottoncandy-theme.png'
import dark from '../../../../assets/images/backgrounds/dark-theme.png'
import dreamy from '../../../../assets/images/backgrounds/dreamy-theme.png'
import gradientWave from '../../../../assets/images/backgrounds/gradient-wave.svg'
import sunset from '../../../../assets/images/backgrounds/sunset-theme.png'
import tech from '../../../../assets/images/backgrounds/tech-theme.png'
import link from '../../../../assets/images/icons/external-link.svg'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { UserContext } from '../../../../contexts/UserContext'
import { findProfile } from '../../../../utils'
import { FormItem } from '../../../general/forms/FormItem'
import { SavingIndicator } from '../../../general/SavingIndicator'
import { ProfileCard } from '../social-accounts/ProfileCard'
import { SocialConnect } from '../social-accounts/SocialConnect'
const platformIcons = { instagram, youtube, tiktok }

const { Option } = Select

export const CreatorProfileEditor = ({ userData }) => {
  const { updateProfileSettings } = useContext(UserContext)
  const { getCategories } = useContext(GlobalContext)
  const creatorProfile = userData.creatorProfile
  const profileSettings = creatorProfile?.profileSettings
  const [profileImage, setProfileImage] = useState(creatorProfile?.profileImg)
  const queryClient = new useQueryClient()
  const submitRef = useRef(0)
  const [unsaved, setUnsaved] = useState(false)
  const [savingStatus, setSavingStatus] = useState(undefined)
  const [formNavVisible, setFormNavVisible] = useState(false)
  const [editHandleMode, setEditHandleMode] = useState(false)
  const [submittingPostLink, setSubmittingPostLink] = useState(false)

  const themes = [
    // NOTE: don't change values (used in backend), labels may be changed
    {
      label: 'Cotton Candy',
      value: 'lightTheme',
      background: cottonCandy,
    },
    {
      label: 'Bright',
      value: 'brightTheme',
      background: bright,
    },
    {
      label: 'Dreamy',
      value: 'dreamyTheme',
      background: dreamy,
    },
    {
      label: 'Gradient Wave',
      value: 'gradientWave',
      background: gradientWave,
    },
    {
      label: 'Dark',
      value: 'darkTheme',
      background: dark,
    },
    {
      label: 'Tech',
      value: 'techTheme',
      background: tech,
    },
    {
      label: 'Sunset',
      value: 'sunsetTheme',
      background: sunset,
    },
    {
      label: 'Baseball',
      value: 'baseballTheme',
      background: baseball,
    },
    {
      label: 'Basketball',
      value: 'basketballTheme',
      background: basketball,
    },
  ]

  // #region PERSONAL INFORMATION SECTION
  const [uploading, setUploading] = useState(false)
  const fileList = []
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  // profile image upload
  const uploadButton = <div>{uploading ? <LoadingOutlined /> : <PlusOutlined />}</div>

  // resize file before upload
  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file, // file to be resized
        800, // maxWidth of the resized image
        800, // maxHeight of the resized image
        'WEBP', // compressFormat of the resized image
        100, // quality of the resized image
        0, // degree of clockwise rotation to apply to the image
        uri => {
          // callback function of the resized image URI
          resolve(uri)
        },
        'file' // outputType of the resized image
      )
    })

  // handling upload
  const handleUpload = async fileData => {
    setUploading(true)

    // resize file to prevent uploading large images to our server
    const image = await resizeFile(fileData.file)
    const formData = new FormData()
    formData.append('upload', image)

    axios
      .post(`${API_URL}/creator-profile-image`, formData)
      .then(res => {
        setProfileImage(res.data.profileSettings.profileImg)
        queryClient.invalidateQueries('user')
        setUploading(false)
      })
      .catch(() => {
        message.error('Oops, something went wrong.')
        setUploading(false)
      })
  }

  // setting countries & states from json files
  useEffect(() => {
    axios
      .get('../json/states.json')
      .then(res => {
        setStates(res.data.states)
      })
      .catch(() => {}) // TODO: handle error
  }, [])

  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {}) // TODO: handle error
  }, [])
  // #endregion PERSONAL INFORMATION SECTION

  // #region AREAS OF INFLUENCE SECTION
  const creatorValues = [
    'Animal Rights',
    'BLM Advocate',
    'Body Positivity',
    'Carbon-conscious',
    'Cruelty-free',
    'Disability Advocate',
    'Eco-friendly',
    'Environmentally Friendly',
    'Fair Trade Advocate',
    'Indigenous Advocate',
    'Keto Enthusiast',
    'LGBTQ+',
    'Mental Health',
    'Sustainability',
    'Vegan',
    'Vegetarian',
    'Zero-waste',
  ]

  const { data: categories } = useQuery('categories', getCategories)
  // #endregion AREAS OF INFLUENCE SECTION

  // #region SOCIAL ACCOUNTS SECTION
  const platforms = ['instagram', 'tiktok', 'youtube']
  const [connected, setConnected] = useState([])
  const [unconnected, setUnconnected] = useState([])

  useEffect(() => {
    // moved to useEffect to prevent infinite loop
    const channels = ['instagram', 'tiktok', 'youtube']

    setConnected(
      channels
        .filter(platform => findProfile(userData?.socialProfiles, platform))
        .map(platform => <ProfileCard platform={platform} toggle={true} key={platform} />)
    )
    setUnconnected(
      channels
        .filter(platform => !findProfile(userData?.socialProfiles, platform))
        .map(platform => <SocialConnect platform={platform} key={platform} />)
    )
  }, [userData])

  // HIGHLIGHTED CONTENT
  const [highlightedContent, setHighlightedContent] = useState([])

  // getting highlighted content data
  useEffect(() => {
    if (profileSettings?.highlightedContent?.length > 0) {
      axios
        .post(`${API_URL}/social-posts`, { ids: profileSettings.highlightedContent })
        .then(res => {
          setHighlightedContent(res.data)
        })
        .catch(() => {}) // TODO: handle error
    }
  }, [profileSettings?.highlightedContent])

  const handleUploadPost = async (values, setValues) => {
    if (values.highlightedContent?.length >= 9) {
      message.error('Max of 9 posts allowed')
      return
    }

    setSubmittingPostLink(true)

    try {
      const res = await axios.post(`${API_URL}/social-posts/upload`, { url: values.postLink })
      await queryClient.invalidateQueries('user')
      setSubmittingPostLink(false)
      message.success('Post uploaded')
      setValues(prev => {
        return {
          ...prev,
          postLink: '',
          highlightedContent: [...prev.highlightedContent, res.data.id],
        }
      })
    } catch (err) {
      message.error(err.response.data.err)
      setSubmittingPostLink(false)
    }
  }
  // #endregion SOCIAL ACCOUNTS SECTION

  const initialValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    profession: userData?.creatorProfile?.profileSettings?.profession,
    bio: userData?.creatorProfile?.profileSettings?.bio,
    shippingRegion: userData?.creatorProfile?.shippingRegion || '',
    shippingCountry: userData?.creatorProfile?.shippingCountry || '',
    birthDate: userData?.creatorProfile?.birthDate || '',
    showLocation: userData?.creatorProfile?.profileSettings?.showLocation || false,
    showAge: userData?.creatorProfile?.profileSettings?.showAge || false,
    email: userData?.creatorProfile?.profileSettings?.email || '',
    certifications: userData?.creatorProfile?.profileSettings?.certifications,
    creatorValues: userData?.creatorProfile?.profileSettings?.creatorValues,
    niches: userData?.creatorProfile?.niches.map(({ category }) => category.id),
    channels: platforms.filter(platform => findProfile(userData?.socialProfiles, platform)),
    rates: profileSettings?.rates || [],
    highlightedContent: profileSettings?.highlightedContent || [],
    productLink: '',
    recommendedProducts: profileSettings?.recommendedProducts || [],
    customLinks: profileSettings?.customLinks || [],
    theme: profileSettings?.theme || 'lightTheme',
  }

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required('First name is required')
      .min(2, 'Please enter at least 2 characters')
      .max(100, 'Please enter a maximum of 100 characters'),
    lastName: yup
      .string()
      .required('Last name is required')
      .min(2, 'Please enter at least 2 characters')
      .max(100, 'Please enter a maximum of 100 characters'),
    profession: yup.string().max(200, 'Please enter a maximum of 200 characters'),
    bio: yup.string(),
    shippingRegion: yup.string().required('Region is required'),
    shippingCountry: yup.string().required('Country is required'),
    birthDate: yup.string().required('Day of birth is required'),
    showLocation: yup.boolean(),
    showAge: yup.boolean(),
    email: yup.string().email('Please enter a valid email'),
    certifications: yup.array(),
    creatorValues: yup.array(),
    niches: yup.array().min(1, 'Select at least 1').max(4, 'Select a maximum of 4'),
    channels: yup.array().min(1, 'You must connect and display at least 1 platform'),
    rates: yup.array().of(
      yup.object().shape({
        service: yup.string().required('Service is required'),
        description: yup.string().required('Description is required'),
        minPrice: yup.number().required('Price is required'),
        maxPrice: yup.number(),
        fixedPrice: yup.boolean(),
      })
    ),
    productLink: yup.string().url('Please enter a valid URL'),
    customLinks: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .max(100, 'Please enter a maximum of 100 characters')
          .required('Title is required'),
        url: yup.string().url('Please enter a valid URL').required('URL is required'),
      })
    ),
  })

  const handleSubmit = async (data, { setStatus }) => {
    // TODO: add logic to check for unsaved changes (due to validation errors) and show alert, test behavior
    // setUnsaved(false)
    // try {
    //   await schema.validate(data, { abortEarly: false })
    // } catch (err) {
    //   setUnsaved(true)
    //   return
    // }
    setSavingStatus('saving')
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      if (thisSubmit === submitRef.current) {
        setStatus('')
        const result = await updateProfileSettings(data)
        if (result.error) {
          setStatus(result.error)
          setSavingStatus('error')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        } else {
          setUnsaved(false)
          setSavingStatus('saved')
          setTimeout(() => {
            setSavingStatus(false)
          }, 2000)
        }
      }
    }, 300)
  }

  return (
    <Wrapper>
      <div className='profile-url-wrapper'>
        <div className='profile-url'>
          <div className='browser-bar'>
            <div
              className='edit-handle-button'
              onClick={() => {
                setEditHandleMode(!editHandleMode)
              }}
              onKeyDown={() => {
                setEditHandleMode(!editHandleMode)
              }}
              role='button'
              tabIndex={0}>
              <EditOutlined />
            </div>
            <span>https://</span>
            <span className='handle'>{userData?.creatorProfile?.profileSlug}</span>
            <span>.creator.co</span>
          </div>
          <div className='link-status-wrapper'>
            {savingStatus ? (
              <SavingIndicator savingStatus={savingStatus} />
            ) : (
              <a
                className='link'
                href={
                  process.env.NODE_ENV === 'production'
                    ? `https://${userData?.creatorProfile?.profileSlug}.creator.co`
                    : `http://${userData?.creatorProfile?.profileSlug}.localhost:3002`
                }
                target='_blank'
                rel='noopener noreferrer'>
                <img src={link} alt='Open Profile' />
              </a>
            )}
          </div>
        </div>
        {editHandleMode && (
          <EditProfileHandle userData={userData} setEditHandleMode={setEditHandleMode} />
        )}
      </div>
      {unsaved && (
        <Alert
          className='unsaved-alert'
          type='warning'
          showIcon
          message='You have unsaved changes. Please be sure to fill in all required fields marked in red.'
        />
      )}
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ status, values, setValues, setFieldValue, submitForm, errors }) => (
          <Form>
            {/* PERSONAL INFORMATION SECTION */}
            <section id='personal-info'>
              <h2>Personal Information</h2>
              {status && <Alert message={status} type='error' showIcon />}
              <div className='form-group'>
                <FormItem label='Profile image' name='profileImg'>
                  <Upload
                    multiple={false}
                    maxCount={1}
                    name='profileImg'
                    customRequest={handleUpload}
                    fileList={fileList}
                    listType='picture-card'
                    accept='.png, .jpg, .jpeg'
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: false,
                    }}>
                    {!uploading && profileImage ? <img src={profileImage} alt='' /> : uploadButton}
                  </Upload>
                </FormItem>
              </div>
              <div className='form-group'>
                <Row gutter={12}>
                  <Col md={12} xs={24}>
                    <FormItem name='firstName' label='First Name'>
                      <Input name='firstName' onChange={submitForm} />
                    </FormItem>
                  </Col>
                  <Col md={12} xs={24}>
                    <FormItem name='lastName' label='Last Name'>
                      <Input name='lastName' onChange={submitForm} />
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div className='form-group'>
                <FormItem label='Profession' name='profession'>
                  <Input
                    name='profession'
                    maxLength={50}
                    placeholder={`e.g. Digital Content Creator`}
                    allowClear
                    onChange={submitForm}
                  />
                </FormItem>
              </div>
              <div className='form-group'>
                <FormItem label='Bio' name='bio'>
                  <Input.TextArea
                    name='bio'
                    maxLength={1000}
                    allowClear
                    showCount
                    rows={4}
                    placeholder='Introduce yourself'
                    onChange={submitForm}
                  />
                </FormItem>
              </div>
              <div className='form-group'>
                <Row gutter={12}>
                  <Col md={12} xs={24}>
                    <FormItem label='Country' name='shippingCountry'>
                      <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        virtual={false}
                        name='shippingCountry'
                        showSearch
                        autoComplete='none'
                        style={{ width: '100%' }}
                        listHeight={256}
                        defaultValue={values.shippingCountry}
                        optionFilterProp='label'
                        onChange={e => {
                          setValues({ ...values, shippingCountry: e })
                          setFieldValue('region', '', true)
                          submitForm()
                        }}>
                        {countries.map(country => (
                          <Option
                            key={country.id}
                            value={country.country_code}
                            label={country.name}>
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col md={12} xs={24}>
                    <FormItem label='State / Province' name='shippingRegion'>
                      <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        virtual={false}
                        name='shippingRegion'
                        showSearch
                        required={true}
                        autoComplete='none'
                        style={{ width: '100%' }}
                        listHeight={256}
                        defaultValue={values.shippingRegion}
                        optionFilterProp='label'
                        onChange={e => {
                          setValues({ ...values, shippingRegion: e })
                          submitForm()
                        }}>
                        {states
                          .filter(state => state.country_code === values.shippingCountry)
                          .map(state => (
                            <Option key={state.id} value={state.name} label={state.name}>
                              {state.name}
                            </Option>
                          ))}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>

                <Checkbox name='showLocation' checked={values.showLocation} onChange={submitForm}>
                  Show my location
                </Checkbox>
              </div>
              <div className='form-group'>
                <FormItem label='Date of birth' name='birthDate'>
                  <DatePicker
                    getPopupContainer={trigger => trigger.parentNode}
                    format='MMMM D, Y'
                    name='birthDate'
                    allowClear={false}
                    style={{ width: '100%' }}
                    disabledDate={current => current.isAfter(moment())}
                    defaultValue={values.birthDate ? moment(values.birthDate) : undefined}
                    onChange={e => {
                      setValues({ ...values, birthDate: e?.toISOString() })
                      submitForm()
                    }}
                  />
                </FormItem>
                <Checkbox name='showAge' checked={values.showAge} onChange={submitForm}>
                  Show my age
                </Checkbox>
              </div>
              <div className='form-group'>
                <FormItem name='email' label='Work Email'>
                  <Input name='email' onChange={submitForm} />
                </FormItem>
              </div>
            </section>

            {/* AREAS OF INFLUENCE SECTION */}
            <section id='areas-of-influence'>
              <h2>Areas of Influence</h2>
              <div className='form-group'>
                <FormItem label='Certifications' name='certifications'>
                  <p className='info-text'>
                    Professional certifications, licenses, and/or social-media related achievements.
                  </p>
                  <Select
                    name='certifications'
                    mode='tags'
                    style={{ width: '100%' }}
                    placeholder='e.g. Certified Aesthetician'
                    defaultValue={values.certifications}
                    onChange={e => {
                      setValues({ ...values, certifications: e })
                      submitForm()
                    }}
                  />
                </FormItem>
              </div>
              <div className='form-group'>
                <FormItem label='Values' name='creatorValues'>
                  <p className='info-text'>
                    Professional and/or personal values reflected in your social media. You can
                    select from our pre-defined values or type to add your own.
                  </p>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='creatorValues'
                    showArrow
                    mode='tags'
                    style={{ width: '100%' }}
                    placeholder='e.g. Eco-conscious'
                    defaultValue={values.creatorValues}
                    onChange={e => {
                      setValues({ ...values, creatorValues: e })
                      submitForm()
                    }}>
                    {creatorValues.map(value => (
                      <Option key={value} value={value} label={value}>
                        {value.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </div>
              <div className='form-group'>
                <FormItem label='Niches' name='niches'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='niches'
                    showArrow
                    mode='multiple'
                    style={{ width: '100%' }}
                    loading={!categories?.length}
                    placeholder='e.g. Beauty & Cosmetics'
                    defaultValue={userData?.creatorProfile?.niches?.map(
                      ({ category }) => category.id
                    )}
                    onChange={e => {
                      setValues(prev => ({ ...prev, niches: e }))
                      submitForm()
                    }}>
                    {categories?.map(category => (
                      <Option label={category.title} key={category.id} value={category.id}>
                        {category.title}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </div>
            </section>

            {/* CHANNELS & RATES SECTION */}
            <section id='social-accounts'>
              <h2>Social Accounts</h2>
              <div className='form-group'>
                <p className='info-text'>
                  Connect the social accounts you would like to showcase on your profile.
                </p>
                <div className='social-connects'>{connected}</div>
                <div className='social-connects'>{unconnected}</div>
              </div>

              <h3 className='subheading'>Services & Rates</h3>
              <p className='info-text'>Add your social media services and rates.</p>
              <FieldArray
                name='rates'
                render={arrayHelpers => (
                  <div>
                    <div className='rates'>
                      {values.rates.map((rate, key) => (
                        <div key={key} className='rate-card'>
                          <FormItem label='Service' name={`rates.${key}.service`}>
                            <Input
                              name={`rates[${key}].service`}
                              placeholder={rate.service || 'e.g. Post'}
                              onChange={e => {
                                setValues(prev => ({
                                  ...prev,
                                  rates: prev.rates.map((r, i) =>
                                    i === key
                                      ? {
                                          ...r,
                                          service: e.target.value,
                                        }
                                      : r
                                  ),
                                }))
                                submitForm()
                              }}
                            />
                          </FormItem>
                          <FormItem label='Description' name={`rates.${key}.description`}>
                            <Input.TextArea
                              name={`rates[${key}].description`}
                              placeholder={
                                rate.description ||
                                'e.g. Personalized post promoting your brand or product.'
                              }
                              onChange={e => {
                                setValues(prev => ({
                                  ...prev,
                                  rates: prev.rates.map((r, i) =>
                                    i === key
                                      ? {
                                          ...r,
                                          description: e.target.value,
                                        }
                                      : r
                                  ),
                                }))
                                submitForm()
                              }}
                            />
                          </FormItem>
                          <div className='form-row'>
                            <FormItem
                              label={values.rates[key].fixedPrice ? 'Price' : 'From'}
                              name={`rates.${key}.minPrice`}>
                              <InputNumber
                                name={`rates[${key}].minPrice`}
                                placeholder='Min price'
                                formatter={value =>
                                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={e => {
                                  setValues(prev => ({
                                    ...prev,
                                    rates: prev.rates.map((r, i) =>
                                      i === key
                                        ? {
                                            ...r,
                                            minPrice: e,
                                          }
                                        : r
                                    ),
                                  }))
                                  submitForm()
                                }}
                              />
                            </FormItem>
                            {!values.rates[key].fixedPrice && (
                              <FormItem label='To' name={`rates.${key}.maxPrice`}>
                                <InputNumber
                                  name={`rates[${key}].maxPrice`}
                                  placeholder='Max price'
                                  formatter={value =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                  onChange={e => {
                                    setValues(prev => ({
                                      ...prev,
                                      rates: prev.rates.map((r, i) =>
                                        i === key
                                          ? {
                                              ...r,
                                              maxPrice: e,
                                            }
                                          : r
                                      ),
                                    }))
                                    submitForm()
                                  }}
                                />
                              </FormItem>
                            )}
                          </div>
                          <Checkbox
                            name={`rates.${key}.fixedPrice`}
                            onChange={e => {
                              setValues(prev => ({
                                ...prev,
                                rates: prev.rates.map((r, i) =>
                                  i === key
                                    ? {
                                        ...r,
                                        fixedPrice: e.target.checked,
                                        maxPrice: e.target.checked ? undefined : r.maxPrice,
                                      }
                                    : r
                                ),
                              }))
                              submitForm()
                            }}>
                            Fixed price
                          </Checkbox>
                          <button
                            type='button'
                            className='remove-button'
                            onClick={() => {
                              arrayHelpers.remove(key)
                              submitForm()
                            }}>
                            <MinusOutlined />
                          </button>
                        </div>
                      ))}
                    </div>
                    <Button
                      type='primary'
                      onClick={() => {
                        arrayHelpers.push({
                          service: '',
                          description: '',
                          minPrice: '',
                          maxPrice: '',
                          fixedPrice: false,
                        })
                      }}>
                      <PlusOutlined /> New Service
                    </Button>
                  </div>
                )}
              />

              <h3 className='subheading'>Highlighted Content</h3>
              <p className='info-text'>
                Upload <b>Instagram</b> or <b>TikTok</b> content to highlight on your profile.
                Metrics will be automatically updated. We recommended uploading 3-6 pieces of your
                best content.
              </p>
              {values.highlightedContent?.length >= 9 && (
                <Alert message='Maximum of 9 content uploaded.' type='warning' showIcon />
              )}
              <FieldArray
                name='highlightedContent'
                render={arrayHelpers => (
                  <>
                    <FormItem name='postLink'>
                      <div className='add-product'>
                        <Input
                          name='postLink'
                          placeholder='Instagram or TikTok Content URL'
                          defaultValue={values.postLink}
                          disabled={submittingPostLink}
                          onPressEnter={e => {
                            // prevents submitting form on enter
                            e.preventDefault()
                          }}
                        />
                        <button
                          className='add-button'
                          type='submit'
                          disabled={
                            !values.postLink ||
                            submittingPostLink ||
                            values.highlightedContent?.length >= 9
                          }
                          onClick={() => handleUploadPost(values, setValues)}>
                          {submittingPostLink ? <LoadingOutlined spin /> : <PlusOutlined />}
                        </button>
                      </div>
                    </FormItem>
                    <div className='highlighted-content'>
                      {values.highlightedContent?.map((id, key) => {
                        const content = highlightedContent.find(content => content.id === id)
                        return !content ? null : (
                          <div className='content-card' key={key}>
                            <div
                              className='thumbnail'
                              style={{
                                backgroundImage: `url(${content.thumbnailUrl})`,
                              }}
                            />
                            <div className='text'>
                              <div className='social'>
                                <img
                                  className='social-icon'
                                  src={platformIcons[content.platform]}
                                  alt={content.platform}
                                />
                                <span className='username'>{content.username}</span>
                              </div>
                              <p className='title'>{content.title || '-'}</p>
                              <div className='metrics'>
                                <div className='row'>
                                  <span>Likes</span>
                                  <span>{content.likes?.toLocaleString() || '-'}</span>
                                </div>
                                <div className='row'>
                                  <span>Comments</span>
                                  <span>{content.comments?.toLocaleString() || '-'}</span>
                                </div>
                              </div>
                              <div className='content-info'>
                                <span className='date'>
                                  {new Date(content.posted).toLocaleDateString()}
                                </span>
                                <span className='format'>{content.format}</span>
                              </div>
                            </div>
                            <button
                              type='button'
                              className='remove-button'
                              onClick={() => {
                                arrayHelpers.remove(key)
                                submitForm()
                              }}>
                              <MinusOutlined />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
              />
            </section>

            {/* RECOMMENDED PRODUCTS SECTION */}
            <RecommendedProducts
              values={values}
              errors={errors}
              setValues={setValues}
              submitForm={submitForm}
            />

            {/* CUSTOM LINKS SECTION */}
            <section id='custom-links'>
              <h2>Custom Links</h2>
              <p className='info-text'>
                Add any additional links you would like to share on your profile.
              </p>
              <FieldArray
                name='customLinks'
                render={arrayHelpers => (
                  <div>
                    <div className='custom-links'>
                      {values.customLinks.map((link, key) => (
                        <div key={key} className='link-card'>
                          <FormItem name={`customLinks[${key}].title`}>
                            <Input
                              name={`customLinks[${key}].title`}
                              placeholder={link.title || 'Title'}
                              bordered={false}
                              prefix={<EditOutlined />}
                              onChange={submitForm}
                            />
                          </FormItem>
                          <FormItem name={`customLinks[${key}].url`}>
                            <Input
                              name={`customLinks[${key}].url`}
                              placeholder={link.url || 'URL'}
                              bordered={false}
                              prefix={<EditOutlined />}
                              onChange={submitForm}
                            />
                          </FormItem>
                          <button
                            type='button'
                            className='remove-button'
                            onClick={() => {
                              arrayHelpers.remove(key)
                              submitForm()
                            }}>
                            <MinusOutlined />
                          </button>
                        </div>
                      ))}
                    </div>
                    <Button
                      type='primary'
                      onClick={() => arrayHelpers.push({ title: '', url: '' })}>
                      <PlusOutlined /> New Link
                    </Button>
                  </div>
                )}
              />
            </section>

            {/* THEME SECTION */}
            <section id='theme'>
              <h2>Theme</h2>
              <Radio.Group
                className='theme-selection'
                name='theme'
                onChange={e => {
                  setValues({
                    ...values,
                    theme: e.target.value,
                  })
                  submitForm()
                }}>
                {themes.map(theme => (
                  <Radio
                    className='theme-card'
                    value={theme.value}
                    style={{ backgroundImage: `url(${theme.background})` }}
                    key={theme.value}>
                    <span className='label'>{theme.label}</span>
                  </Radio>
                ))}
              </Radio.Group>
            </section>
          </Form>
        )}
      </Formik>
      <div className='form-nav-wrapper'>
        <div
          className={`overlay ${formNavVisible && 'visible'}`}
          onClick={() => setFormNavVisible(false)}
          onKeyDown={() => setFormNavVisible(false)}
          role='button'
          tabIndex={0}
        />
        <div className='fab-wrapper'>
          <div
            className={`links ${formNavVisible && 'expand'}`}
            onClick={() => setFormNavVisible(false)}
            onKeyDown={() => setFormNavVisible(false)}
            role='button'
            tabIndex={0}>
            <a href='#personal-info'>Personal Information</a>
            <a href='#areas-of-influence'>Areas of Influence</a>
            <a href='#social-accounts'>Channels & Rates</a>
            <a href='#recommended-products'>Recommended Products</a>
            <a href='#custom-links'>Custom Links</a>
            <a href='#theme'>Theme</a>
          </div>
          <div
            className={`fab ${formNavVisible && 'close'}`}
            onClick={() => setFormNavVisible(!formNavVisible)}
            onKeyDown={() => setFormNavVisible(!formNavVisible)}
            role='button'
            tabIndex={0}>
            {formNavVisible ? <CloseCircleOutlined /> : <BarsOutlined />}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  font-size: 1rem;
  line-height: 1.75;
  section {
    scroll-margin-top: 80px;
    background: #fff;
    padding: 20px;
  }
  .subheading {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .unsaved-alert {
    position: sticky;
    top: 90px;
    z-index: 1;
  }
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile-url-wrapper {
    background: #fff;
    position: sticky;
    top: 50px;
    z-index: 2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .profile-url {
      display: flex;
      align-items: center;
    }
    .browser-bar {
      color: #999;
      flex: 1;
      padding: 5px 10px;
      font-size: 1rem;
      max-width: 100%;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      .edit-handle-button {
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 50%;
        margin-right: 10px;
        display: grid;
        place-content: center;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        height: 25px;
        width: 25px;
        &:hover {
          background: #eee;
        }
      }
      .handle {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .link-status-wrapper {
      height: 25px;
      width: 25px;
      display: grid;
      place-content: center;
      margin: 0 5px;
    }
    .link {
      background: #ecf6ff;
      display: grid;
      place-content: center;
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      textarea {
        padding: 10px 30px 10px 10px;
      }
    }
  }
  .checkbox-label {
    margin-left: 8px;
    cursor: pointer;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    overflow: hidden;
  }
  .social-connects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
  .highlighted-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  .content-card {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 10px;
    position: relative;
    transition: 0.2s ease-in-out;
    overflow: hidden;
    .thumbnail {
      width: 150px;
      background-color: rgba(0, 0, 0, 0.1);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 0.8rem;
      padding: 10px;
      .social {
        display: flex;
        .social-icon {
          height: 20px;
          margin-right: 5px;
        }
        .username {
          color: ${props => props.theme.crcoTechBlue};
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .title {
        font-size: 0.8rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 10px 0;
      }
      .metrics {
        color: #666;
        margin-bottom: 10px;
        .row {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px dotted #ccc;
        }
      }
      .content-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        grid-gap: 10px;
        .date {
          color: rgba(0, 0, 0, 0.5);
        }
        .format {
          background: #ecf6ff;
          color: #027df0;
          padding: 2px 5px 0 5px;
          border-radius: 3px;
          font-size: 0.7rem;
        }
      }
    }
    .add-button,
    .remove-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  .num-products {
    font-size: 0.8rem;
    font-weight: normal;
    .value {
      opacity: 0.7;
      &.max {
        opacity: 1;
        color: ${props => props.theme.crcoCoral};
      }
    }
  }
  .add-product {
    display: flex;
    align-items: center;
    gap: 10px;
    .ant-input {
      width: auto;
      flex: 1;
    }
  }
  .add-button,
  .remove-button {
    background: rgba(137 137 137 / 50%);
    color: #fff;
    backdrop-filter: blur(10px);
    border: none;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    width: 25px;
    height: 25px;
    display: grid;
    place-content: center;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &.checked {
      background: ${props => props.theme.crcoTechBlue};
      cursor: default;
    }
    &:not(.checked):disabled {
      pointer-events: none;
    }
    &:not(.checked):hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  .close-btn {
    color: #999;
    background: transparent;
    border: none;
    border-radius: 50%;
    padding: 5px;
    display: grid;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
  .products {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  .product {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    .button-wrapper {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      gap: 10px;
    }
  }
  .product-card {
    background: ${props => props.theme.crcoOffWhite};
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      .title {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .placeholder-thumbnail {
      background: #eee;
      display: grid;
      place-content: center;
      height: 150px;
      img {
        opacity: 0.3;
        height: 50px;
        width: 50px;
      }
    }
    .thumbnail {
      height: 150px;
      overflow: hidden;
    }
    .text {
      flex: 1;
      padding: 20px;
      color: ${props => props.theme.crcoMidnight};
      display: flex;
      flex-direction: column;
    }
    .title {
      font-size: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.2s ease-in-out;
      &.no-info {
        opacity: 0.5;
      }
    }
    .sitename {
      margin-top: auto;
      margin-bottom: 0;
      font-size: 0.8rem;
    }
  }
  .custom-links,
  .rates {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  .link-card,
  .rate-card {
    background: #ecf6ff;
    border: 1px solid #dbeeff;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    .form-row {
      display: flex;
      gap: 12px;
    }
    .ant-input-number {
      max-width: 100%;
    }
    .ant-input-prefix {
      color: rgba(0, 0, 0, 0.2);
      margin-right: 8px;
    }
    .remove-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .rate-card {
    padding: 20px 10px;
  }
  .theme-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .theme-card {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 180px;
      border: 2px solid #fff;
      border-radius: 15px;
      margin: 0;
      text-align: center;
      overflow: hidden;
      transition: 0.2s ease-in-out;
      position: relative;
      &:hover {
        border-color: ${props => props.theme.crcoTechBlue};
      }
      &.ant-radio-wrapper-checked {
        border-color: ${props => props.theme.crcoTechBlue};
        .label {
          background: ${props => props.theme.crcoTechBlue};
          color: #fff;
        }
      }
      &.ant-radio-wrapper-disabled {
        cursor: default;
        pointer-events: none;
      }
      &.coming-soon {
        filter: grayscale(1);
      }
      .ant-radio {
        display: none;
      }
      span {
        padding: 0;
      }
      .label {
        position: absolute;
        bottom: 10px;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(5px);
        color: #111;
        left: 50%;
        transform: translate(-50%);
        width: 90%;
        border-radius: 20px;
        padding: 3px;
        transition: 0.2s ease-in-out;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 20px;
  }
  .form-nav-wrapper {
    &.hidden {
      display: none;
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      visibility: hidden;
      opacity: 0;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      z-index: 1;
      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
    .fab-wrapper {
      position: fixed;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 2;
      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        max-height: 0;
        margin-bottom: 20px;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease-in-out;
        z-index: 99;
        a {
          background: #fff;
          border-radius: 20px;
          padding: 5px 10px;
          font-size: 0.9rem;
        }
        &.expand {
          max-height: 300px;
          visibility: visible;
          opacity: 1;
        }
      }
      .fab {
        background: ${props => props.theme.crcoTechBlue};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        color: #fff;
        border: none;
        display: grid;
        place-content: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        z-index: 100;
        cursor: pointer;
        &.close {
          background: ${props => props.theme.crcoCoral};
        }
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    width: 100%;
    margin: auto;
    section {
      padding: 20px;
    }
    form {
      gap: 20px;
    }
    .highlighted-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      .content-card {
        flex-direction: column;
        .thumbnail {
          height: 200px;
          width: 100%;
        }
      }
    }
    .products {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .product {
        flex-direction: column;
      }
    }
    .theme-selection {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    padding: 20px;
    max-width: 800px;
    section {
      padding: 40px;
    }
    form {
      gap: 30px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktopWide}) {
    padding-right: 10px;
    section {
      border-radius: 5px;
    }
    .profile-url-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
    }
    .highlighted-content {
      grid-template-columns: repeat(3, 1fr);
    }
    .products {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .theme-selection {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`
