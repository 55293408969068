import { EyeInvisibleOutlined, EyeTwoTone, FacebookFilled } from '@ant-design/icons'
import FacebookLogin from '@greatsumini/react-facebook-login'
import { Alert, Button, Modal, Carousel } from 'antd'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Redirect, Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import { ForgotPass } from './ForgotPass'
// CAROUSEL IMAGES
import creator1 from '../../assets/images/login/creator1.png'
import creator2 from '../../assets/images/login/creator2.png'
import creator3 from '../../assets/images/login/creator3.png'
import creator4 from '../../assets/images/login/creator4.png'
import creator5 from '../../assets/images/login/creator5.png'
import hashtag from '../../assets/images/login/hashtag.svg'
import image1 from '../../assets/images/login/image1.png'
import image2 from '../../assets/images/login/image2.png'
import image3 from '../../assets/images/login/image3.png'
import image4 from '../../assets/images/login/image4.png'
import image5 from '../../assets/images/login/image5.png'
import logo1 from '../../assets/images/login/logo1.png'
import logo2 from '../../assets/images/login/logo2.png'
import logo3 from '../../assets/images/login/logo3.png'
import logo5 from '../../assets/images/login/logo5.png'
import logo4 from '../../assets/images/signup/logo3.png'
import { FormItem } from '../../components/general/forms/FormItem'
import { ImageLoader } from '../../components/general/ImageLoader'
import { UserContext } from '../../contexts/UserContext'
import { useMetaData } from '../../custom-hooks/useMetaData'

const fbAppId = process.env.REACT_APP_FB_APP_ID
const queryString = require('query-string')

export const Login = () => {
  useMetaData(
    'Log in | Creator Dashboard',
    'Explore hundreds of social media collaborations, opt in, and track your progress!',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  const history = useHistory()
  const location = useLocation()
  const { loginHandler } = useContext(UserContext)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    const { return_to } = queryString.parse(location.search)
    if (return_to) localStorage.setItem('zendesk-ref', return_to)
  }, [location])

  const handleSubmit = async (data, { setSubmitting, setStatus }) => {
    setStatus('')
    setSubmitting(true)

    const result = await loginHandler(data)
    if (result.err) {
      setStatus(result.err)
      setSubmitting(false)
    } else {
      const { redirect } = queryString.parse(location.search)
      history.push(!redirect ? '/' : redirect)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  })

  const initialValues = { username: '', password: '' }

  // FACEBOOK LOGIN
  const [fbSubmitting, setFbSubmitting] = useState(false)
  const [fbError, setFbError] = useState()

  const responseFacebook = async response => {
    const { accessToken: fbToken } = response
    if (fbToken) {
      setFbSubmitting(true)
      const result = await loginHandler({ fbToken })
      setFbSubmitting(false)
      if (result.error) {
        setFbError(result.error.msg)
      } else {
        const { redirect } = queryString.parse(location.search)
        history.push(redirect == null ? '/' : redirect)
      }
    }
  }

  const carouselContent = [
    {
      logo: logo5,
      image: image5,
      subtext: `Paid partnership with Wyder's Cider`,
      caption: `Unplugging myself from reality for a bit with @wyderscider 🍂`,
      creator: {
        avatar: creator5,
        username: '@wearabbygoes',
      },
    },
    {
      logo: logo4,
      image: image4,
      subtext: 'Paid partnership with FreeYumm',
      caption: `My girl and I were so happy to have discovered these cheezy cracker bites from @FreeYummfoods 🧀🍪`,
      creator: {
        avatar: creator4,
        username: '@wren_lily3',
      },
    },
    {
      logo: logo1,
      image: image1,
      subtext: 'Paid partnership with Embody',
      caption:
        '🌺 Let me introduce you to my new favorite Ultimate Skincare Routine from @thisisembody',
      creator: {
        avatar: creator1,
        username: '@vmarci17',
      },
    },
    {
      logo: logo2,
      image: image2,
      subtext: 'Paid partnership with Bela Brand Seafood',
      caption: `Each package of sardines is filled with plump, juicy, steak sized fillets 😋 🍽`,
      creator: {
        avatar: creator2,
        username: '@thestyleguyla',
      },
    },
    {
      logo: logo3,
      image: image3,
      subtext: 'Paid partnership with Lucky Iron Fish',
      caption:
        'The Lucky Iron Fish 🐟 is a simple, reusable and effective cooking tool to get extra iron in your diet',
      creator: {
        avatar: creator3,
        username: '@thesaltycooker',
      },
    },
  ]

  return !localStorage.getItem('crcoToken') ? (
    <Wrapper>
      <div className='inner'>
        <section className='carousel'>
          <Carousel autoplay>
            {carouselContent.map((item, index) => (
              <div className='testimonial-card' key={index}>
                <img className='logo' src={item.logo} alt={`Wyder's Cider`} />
                <ImageLoader src={item.image} alt='' />
                <div className='text'>
                  <p className='subtext'>{item.subtext}</p>
                  <p className='caption'>{item.caption}</p>
                  <div className='creator-info'>
                    <div className='avatar'>
                      <img src={item.creator.avatar} alt='' />
                    </div>
                    {item.creator.username}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </section>
        <section className='login-form-container'>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}>
            {({ values, status, isSubmitting }) => (
              <Form>
                <img src={hashtag} alt='' className='hashtag' />
                <h1>Good to have you back!</h1>
                <p className='subtext'>Please log in to access your creator dashboard.</p>
                <FormItem name='email'>
                  <Input type='text' name='email' placeholder='Email Address' />
                </FormItem>
                <FormItem name='password'>
                  <Input.Password
                    name='password'
                    placeholder='Password'
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </FormItem>
                <div className='forgot-pass'>
                  <ForgotPassword onClick={() => setModalVisible(true)}>
                    <p>Forgot password?</p>
                  </ForgotPassword>
                  <Modal
                    title='Reset Password'
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    destroyOnClose
                    footer={null}>
                    <ForgotPass
                      loginEmail={values.email}
                      closeModal={() => setModalVisible(false)}
                    />
                  </Modal>
                </div>
                <div className='buttons'>
                  {status && (
                    <Alert message={status} type='error' showIcon style={{ width: '100%' }} />
                  )}
                  <Button type='primary' htmlType='submit' loading={isSubmitting}>
                    Sign In
                  </Button>
                  <div className='divider'>
                    <span /> OR <span />
                  </div>
                  {fbError && (
                    <Alert
                      style={{ width: '100%', marginBottom: '10px' }}
                      type='error'
                      message={fbError}
                    />
                  )}
                  <FacebookLogin
                    render={renderProps => (
                      <Button
                        onClick={renderProps.onClick}
                        type='secondary'
                        icon={<FacebookFilled />}
                        loading={fbSubmitting}>
                        Sign in with Facebook
                      </Button>
                    )}
                    onSuccess={responseFacebook}
                    appId={fbAppId}
                    fields='id,name,email'
                  />
                </div>
                <div className='sub-links'>
                  Don&apos;t have an account? <Link to='/signup'>Sign Up</Link>
                </div>
              </Form>
            )}
          </Formik>
          <div className='sub-links brand'>
            Are you a Brand? <a href='https://brands.creator.co/login'>Log In Here</a>
          </div>
        </section>
      </div>
    </Wrapper>
  ) : (
    <Redirect to='/my-dashboard' />
  )
}

export const Wrapper = styled.div`
  background: #f4f4f8;
  color: #23262f;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins-Regular', sans-serif;
  .inner {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .carousel {
    width: 100%;
    position: absolute;
    overflow: hidden;
    .slick-dots {
      margin: 0 0 15px 0;
      li button:before {
        display: none;
      }
    }
    .testimonial-card {
      position: relative;
      aspect-ratio: 1;
      .image {
        height: 100%;
        display: flex;
        img {
          height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }
    }
    .logo,
    .text {
      display: none;
    }
  }
  .login-form-container {
    width: 90%;
    flex: 1;
    position: relative;
    margin: 30vh 20px 20px 20px;
    z-index: 20;
    .hashtag {
      position: absolute;
      top: -55px;
      left: 50%;
      transform: translateX(-50%);
    }
    h1 {
      font-family: 'Poppins-Bold', sans-serif;
      text-align: center;
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.2;
    }
    .subtext {
      font-size: 12px;
      text-align: center;
      opacity: 0.7;
      margin: 15px 0;
    }
    form {
      background: #fff;
      box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      width: 100%;
      .ant-input-affix-wrapper {
        background: #f4f4f8;
        border-radius: 20px;
        padding: 0;
        border: none;
        .ant-input-suffix {
          position: absolute;
          right: 0;
          height: 100%;
          width: 30px;
        }
      }
      .ant-input {
        background: #f4f4f8;
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
      }
      // PHONE NUMBER INPUTS (signup)
      .antd-country-phone-input .ant-input-group-addon {
        background: #f4f4f8;
        border: none;
        border-radius: 20px 0 0 20px;
      }
      .ant-input-group {
        .ant-input {
          border-radius: 0 20px 20px 0;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin: 25px 0;
      button {
        border: none;
        height: 44px;
        font-size: 1rem;
      }
      .ant-btn-primary {
        width: 100%;
        background: ${props => props.theme.crcoMidnight};
      }
      .ant-btn-secondary {
        // styling to match google button on brand side
        height: 32px;
        font-size: 14px;
        background: #4769b1;
        color: #fff;
      }
      .divider {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        opacity: 0.3;
        span {
          flex: 1;
          height: 1px;
          background-color: #555;
        }
      }
    }
    .sub-links {
      text-align: center;
      &.brand {
        margin: 20px;
        color: ${props => props.theme.crcoTechBlue};
        a {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
      a {
        color: #23262f;
        font-family: 'Poppins-Bold', sans-serif;
        transition: 0.2s ease-in-out;
        &:hover,
        &:focus {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }

  // TABLET
  @media only screen and (min-width: 500px) {
    justify-content: center;
    .carousel {
      max-width: 600px;
      margin: 20px;
      .testimonial-card {
        border-radius: 0 40px 0 40px;
        overflow: hidden;
        aspect-ratio: 4/3;
      }
      .logo {
        display: block;
        position: absolute;
        top: 40px;
        right: 40px;
        max-height: 100px;
        max-width: 100px;
      }
    }
    .login-form-container {
      width: 400px;
      h1 {
        font-size: 1.7rem;
      }
      .subtext {
        font-size: 1rem;
      }
    }
  }

  // DESKTOP
  @media only screen and (min-width: 1100px) {
    &.signup {
      .inner {
        flex-direction: row;
      }
    }
    .inner {
      flex: 1;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      margin: auto;
    }
    .carousel {
      position: relative;
      margin: 30px;
      max-width: 50%;
      max-height: calc(100vh - 110px);
      border-radius: 0 40px 0 40px;
      overflow: hidden;
      .testimonial-card {
        aspect-ratio: 3/4;
        margin: 0;
        max-height: calc(100vh - 110px);
      }
      .text {
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
        font-family: 'Poppins-Medium', sans-serif;
        width: 100%;
        color: #fff;
        position: absolute;
        bottom: 0;
        padding: 30px 30px 80px 30px;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 0 0 0 40px;
      }
      p {
        margin: 0;
      }
      .subtext {
        font-size: 1rem;
        opacity: 0.8;
      }
      .caption {
        font-size: 30px;
        font-family: 'Poppins-Bold', sans-serif;
        margin: 5px 0;
        font-weight: normal;
        line-height: 1.2;
        margin: 10px 0;
      }
      .creator-info {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 18px;
      }
      .avatar {
        background: linear-gradient(to bottom right, lime, yellow);
        padding: 3px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        img {
          border-radius: 50%;
        }
      }
    }
    .login-form-container {
      margin: 80px 30px 30px 30px; // more margin on top to account for hashtag
      max-width: 500px;
      form {
        padding: 60px 40px;
      }
      .form-row {
        display: flex;
        gap: 12px;
      }
    }
  }
`

const ForgotPassword = styled.div`
  width: fit-content;
  cursor: pointer;
  p {
    font-family: 'Poppins-Bold', sans-serif;
    font-size: 11px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    margin: 0 10px;
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
  }
`
